<template>
  <section id="kontakt" class="kontakt">
    <span class="font--vertical font--large">kommunikatives</span>
    <h2 class="headline">{{ msg }}</h2>

    <!-- <form id="contactForm" class="form" name="contactForm" method="post" action="be.php">
      <div class="input-field">
        <input type="text" id="name" required>
        <label for="name">Name*</label>
      </div>
      <div class="input-field">
        <input type="text" id="mail" required>
        <label for="mail">E-Mail*</label>
      </div>
      <div class="input-field">
        <textarea type="text" id="textarea" rows="3" name="message" required></textarea>
        <label for="textarea">Nachricht*</label>
      </div>
      <div class="hint">
        <div class="g-recaptcha" data-sitekey="6LfQUwsUAAAAAAM2v9VRrZ7vszmODBCQDM7FmmE7"></div>
      </div>
      <p>*Pflichtfelder</p>
      <SimpleButton>Hier klicken</SimpleButton>
    </form>-->
    <div class="kontakt__data">
      <p>
        Désirée Wittler
        <br>Am Alten Broich 130
        <br>40764 Langenfeld
      </p>
      <p>
        <span>E-Mail: desi</span>
        <span class="secret-mail">Denkste!</span>
        <span>@desi</span>
        <span class="secret-mail">Haha, nich mit mir</span>
        <span>-gn.de</span>
      </p>
      <div class="form">
        <SimpleLink :href="'mailto:desi@desi-gn.de'">E-Mail schreiben</SimpleLink>
      </div>
    </div>
  </section>
</template>

<script>
//import SimpleButton from "./SimpleButton.vue";
import SimpleLink from "./SimpleLink.vue";

export default {
  name: "Kontakt",
  components: {
    //SimpleButton
    SimpleLink
  },
  props: {
    msg: String
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
@import "../../scss/custom/variables.scss";

.kontakt {
  background: $brand-green;

  @media screen and (min-width: 1400px) {
    border-right: 20px solid $brand-green-dark;
  }

  &__data {
    grid-column: 2 / -1;

    @media (min-width: 768px) {
      grid-column: 2 / span 4;
    }

    p {
      margin-top: 0;
    }

    .form {
      margin-top: 20px;
      display: inline-block;
    }
  }

  /*   .form {
    grid-column: 2 / -2;

    @media (min-width: 768px) {
      grid-column: 2 / span 4;
    }
  } */

  .input-field {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
  }

  label {
    position: absolute;
    top: 0;
    left: 0;
    padding: 30px 20px 14px;
    width: 100%;
    color: $text-color;
    transition: 0.2s all;
    font-size: $font--xsmall;
  }

  input,
  textarea {
    width: 100%;
    border: 0;
    padding: 30px 20px 14px;
    outline: 0;
    border-bottom: 2px solid $brand-grey-dark;
    box-sizing: border-box;
    font-size: $font--xsmall;
  }

  input:focus,
  input:valid,
  textarea {
    border-color: $brand-green-dark;
  }

  input:focus ~ label,
  input:valid ~ label,
  textarea:focus ~ label,
  textarea:valid ~ label {
    top: -28px;
    font-size: $font--xxsmall;
    color: $brand-green-dark;

    @media (min-width: 768px) {
      top: -32px;
    }
  }

  .captcha--error {
    border: 3px solid #952a29;
    width: 308px;
  }

  .secret-mail {
    display: none;
  }
}
</style>
