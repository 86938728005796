<template>
<ul class="social">
    <li>
        <a href="https://bitbucket.org/deeesi/" target="_blank"><img src="../assets/svg/social-bitbucket.svg" alt="go to my bitbucket" /></a>
    </li>
    <li>
        <a href="https://codepen.io/collection/XmoqjN" target="_blank"><img src="../assets/svg/social-codepen.svg" alt="go to my codepen" /></a>
    </li>
    <li>
        <a href="https://www.xing.com/profile/Desiree_Wittler" target="_blank"><img src="../assets/svg/social-xing.svg" alt="go to my xing profile" /></a>
    </li>
    <li>
        <a href="https://www.linkedin.com/in/d%C3%A9sir%C3%A9e-wittler-031807171/" target="_blank"><img src="../assets/svg/social-linkedin.svg" alt="go to my linked in profile" /></a>
    </li>
</ul>
</template>

<script>
export default {
    name: 'Social',
    components: {},
    props: {}
};
</script>

<style lang="scss" scoped>
@import '../../scss/custom/variables.scss';

.social {
    z-index: 1;
    grid-row-start: 4;
    grid-column: 2 / span 8;

    @media (min-width: 768px) {
        grid-column: 2 / span 4;
    }

    li {
        display: inline;
        margin-right: 24px;

        &:last-child {
            margin-right: 0;
        }
    }

    @media (min-width: 768px) {
        li {
            margin-right: 18px;
        }
    }

    @media (min-width: 1024px) {
        li {
            margin-right: 24px;
        }
    }

    a {
        transition: all 0.3s;

        @media (min-width: 768px) {
            font-size: $font--xxsmall;
        }

        &:hover {
            color: $white;
            opacity: 0.6;
        }
    }
}
</style>
