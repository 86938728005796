<template>
	<nav>
		<ul>
			<li><a href="#" title="persönliches" v-scroll-to="'#persoenliches'">persönliches</a></li>
			<li><a href="#" title="gelerntes" v-scroll-to="'#gelerntes'">gelerntes</a></li>
			<li><a href="#" title="geleistetes" v-scroll-to="'#geleistetes'">geleistetes</a></li>
		</ul>
	</nav>
</template>

<script>
export default {
	name: 'Navigation',
	props: {},
	methods: {}
};
</script>

<style lang="scss" scoped>
@import '../../scss/custom/variables.scss';

nav {
	grid-column: 7 / span 3;
	grid-row-start: 2;
	z-index: 1;

	@media screen and (min-width: 768px) {
		grid-column: 2 / span 4;
		grid-row-start: 3;
	}
}

ul {
	li {
		margin-bottom: 14px;
		text-align: right;

		@media screen and (min-width: 768px) {
			margin-bottom: 20px;
			text-align: left;
		}

		@media screen and (min-width: 1024px) {
			margin-bottom: 25px;
		}

		@media screen and (min-width: 1280px) {
			margin-bottom: 40px;
		}
	}
}

a {
	transition: all 0.3s;

	&:hover {
		color: $white;
	}
}
</style>
