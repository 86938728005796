<template>
  <div id="referenzTangram" class="overlay referenzTangram">
    <div class="popup">
      <h2>Flying Tangram</h2>
      <a class="close" href="#geleistetes">&times;</a>
      <div class="content">
        <p>Animierte Tangram Tiere ohne ein einziges Bild und ohne JavaScript</p>
        <div class="img__wrapper">
          <img
            src="img/referenzen/referenzen_desk_tangram.png"
            alt="Tangram auf Desktop Rechner"
            class="referenz__img referenz__img--desk"
          >
          <img
            src="img/referenzen/referenzen_mobil_tangram.png"
            alt="Tangram auf Smartphone"
            class="referenz__img referenz__img--mobile"
          >
        </div>
        <ul>
          <li>Funktioniert mit Radiobuttons</li>
          <li>CSS3 Transitions</li>
          <li>Geometrische Formen mit CSS3</li>
          <li>Responsive</li>
        </ul>
        <SimpleLink :href="'http://tangram.krabamms.de/'" target="_blank">Projekt ansehen</SimpleLink>
      </div>
    </div>
  </div>
</template>

<script>
import SimpleLink from "./SimpleLink.vue";

export default {
  name: "ReferenzTangram",
  components: {
    SimpleLink
  }
};
</script>

<style lang="scss" scoped>
@import "../../scss/custom/variables.scss";

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: rgb(255, 255, 255);
  transition: opacity 200ms;
  visibility: hidden;
  opacity: 0;
  z-index: 10;
  .cancel {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  &:target {
    visibility: visible;
    opacity: 1;
  }
  h2,
  .h2 {
    color: white;
    margin-top: 40px;
    margin-bottom: 10px;
  }
}

.popup {
  padding: 40px 20px;
  background: $brand-green;
  width: 100%;
  box-sizing: border-box;

  box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
  position: relative;
  .light & {
    border-color: #aaa;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
  }
  .close {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 0;
    right: 50px;
    opacity: 0.8;
    transition: all 200ms;
    font-size: 140px;
    line-height: 90px;
    font-weight: 200;
    text-decoration: none;
    color: white;
    &:hover {
      opacity: 1;
    }
  }
  .content {
    max-width: 1000px;

    .img__wrapper {
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      grid-template-rows: repeat(8, 50px);

      @media (min-width: 640px) {
        grid-template-rows: repeat(8, 100px);
      }
    }

    .referenz__img {
      width: 100%;

      &--desk {
        grid-column: 1 / span 7;
        grid-row: 1 / -1;
      }

      &--mobile {
        grid-column: 6 / -1;
        grid-row-start: 3;
      }
    }

    ul {
      list-style: none;
      margin: 0 0 30px 0;
      padding-left: 0;

      li {
        margin-bottom: 10px;

        &:before {
          content: "";
          width: 10px;
          height: 10px;
          background: $brand-green-dark;
          display: inline-block;
          margin: 0 20px 5px 0;
        }
      }
    }
  }
}
</style>
