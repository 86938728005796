<template>
  <a :href="refLink" class="referenz referenz__effect" :id="id" :target="target">
    <div class="referenz__img-wrapper">
      <img :src="fileName" :alt="imgAlt">
      <div class="referenz__info-wrapper">
        <span class="info">{{infotext}}</span>
      </div>
    </div>

    <div class="referenz__data">
      <h3>{{ headline }}</h3>
      <p>{{ desc }}</p>
    </div>
  </a>
</template>

<script>
export default {
  name: "Referenz",
  props: {
    headline: String,
    desc: String,
    infotext: String,
    fileName: String,
    imgAlt: String,
    refLink: String,
    id: String,
    target: String
  }
};
</script>

<style lang="scss" scoped>
@import "../../scss/custom/variables.scss";

.referenz {
  img {
    display: flex;
    width: 100%;
  }

  &__data {
    background: $brand-green-light;
    padding: 40px 30px;
    p {
      margin-bottom: 0;
    }
  }

  &__img-wrapper {
    position: relative;
    border: 3px solid $brand-green-light;
    overflow: hidden;
  }

  &__effect {
    overflow: hidden;

    img {
      display: block;
      position: relative;
      transform: scale(1);
      transition: all 0.4s ease-in-out;
    }

    .referenz__info-wrapper {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transition: all 0.4s ease-in-out;
      display: flex;
    }

    .info {
      text-decoration: none;
      padding: 22% 0 0 0;
      color: #fff;
      border: 1px solid #fff;
      margin: 20px;
      background-color: transparent;
      opacity: 0;
      filter: alpha(opacity=0);
      transform: scale(1.5);
      transition: all 0.4s ease-in-out;
      text-align: center;
      width: 100%;
    }

    &:hover {
      .referenz__info-wrapper {
        background-color: rgba(0, 0, 0, 0.4);
      }

      img {
        transition: all 0.4s ease-in-out;
        transform: scale(1.1);
      }

      .info {
        opacity: 1;
        filter: alpha(opacity=100);
        transform: scale(1);
        background-color: rgba(99, 150, 153, 0.4);
      }
    }
  }
}
</style>
