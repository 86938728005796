<template>
	<ul class="metanavi">
		<li><a href="#" v-scroll-to="'#kontakt'" >Kontakt</a></li>
		<li><a href="#impressum" class="impressum" id="impressumButton">Impressum</a></li>
	</ul>
</template>

<script>
export default {
	name: 'Metanavi',
	components: {},
	props: {}
};
</script>

<style lang="scss" scoped>
@import '../../scss/custom/variables.scss';

.metanavi {
	z-index: 1;
	grid-column: 2 / span 6;
	grid-row-start: 5;

	@media (min-width: 768px) {
		grid-column: 2 / span 3;
	}

	li {
		display: inline;
		margin-right: 24px;

		&:last-child {
			margin-right: 0;
		}
	}

	a {
		transition: all 0.3s;

		@media (min-width: 768px) {
			font-size: $font--xxsmall;
		}

		&:hover {
			color: $white;
		}
	}
}
</style>
