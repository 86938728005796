<template>
<section id="gelerntes" class="kenntnisse">
    <span class="font--vertical font--large">gelerntes</span>
    <h2 class="headline">{{ msg }}</h2>

    <div class="bookshelf__wrapper">
        <div class="bookshelf__books">
            <div class="book">
                <span class="stripe stripe--small full-width"></span>
                <span class="rectangle rectangle--white">
                    <span class="book__title book__title--large book__title--bottom">HTML5</span>
                </span>
                <span class="stripe stripe--large no-margin"></span>
            </div>

            <div class="book book--color3">
                <span class="stripe stripe--small full-width"></span>
                <span class="circle"></span>
                <span class="rectangle rectangle--large">
                    <span class="book__title book__title--large">CSS</span>
                </span>
                <span class="stripe stripe--double stripe--small full-width"></span>
            </div>

            <div class="book book--small book--color2">
                <span class="stripe stripe--small full-width"></span>
                <span class="rectangle rectangle--large">
                    <span class="book__title book__title--small">SCSS/LESS-Syntax</span>
                </span>
                <span class="stripe stripe--small full-width"></span>
            </div>

            <div class="book book--color4">
                <span class="rectangle rectangle--large">
                    <span class="book__title book__title--bottom">
                        Responsive / Adaptive
                        <br />
                        <span class="book__title--small">für Desktop und mobile Geräte</span>
                    </span>
                </span>
                <span class="stripe stripe--large no-margin"></span>
            </div>

            <div class="book book--small">
                <span class="rectangle rectangle--large">
                    <span class="book__title book__title--medium">BEM</span>
                </span>
                <span class="stripe stripe--double stripe--small full-width"></span>
            </div>

            <div class="book book--small book--color2">
                <span class="stripe stripe--small full-width"></span>
                <span class="circle"></span>
                <span class="rectangle rectangle--large">
                    <span class="book__title book__title--small">Atomic Design</span>
                </span>
                <span class="stripe no-margin"></span>
            </div>

            <div class="book book--color3">
                <span class="rectangle rectangle--large">
                    <span class="book__title book__title--medium">
                        Frontend-Frameworks
                        <br />
                        <span class="book__title--small">Bootstrap, Foundation...</span>
                    </span>
                </span>
                <span class="stripe stripe--double stripe--small full-width"></span>
            </div>

            <div class="book book--small book--color4">
                <span class="stripe stripe--small full-width"></span>
                <span class="rectangle rectangle--large">
                    <span class="book__title book__title--bottom">Shopware 5 &amp; 6</span>
                </span>
                <span class="stripe stripe--large no-margin"></span>
            </div>

            <div class="book book--small book--leaning">
                <span class="stripe stripe--small full-width"></span>
                <span class="rectangle rectangle--white">
                    <span class="book__title book__title--small">Versionierung per Git</span>
                </span>
                <span class="stripe stripe--double stripe--small full-width"></span>
            </div>
            <img src="img/cactus.svg" class="deko" />
        </div>

        <div class="bookshelf"></div>
    </div>
    <div class="bookshelf__wrapper">
        <div class="bookshelf__books">
            <img src="img/flower.svg" class="deko book--row-two" />

            <div class="book book--color3">
                <span class="rectangle rectangle--white">
                    <span class="book__title">Cross-Browser Kompatibilität</span>
                </span>
                <span class="stripe stripe--double stripe--small full-width"></span>
            </div>

            <div class="book book--small book--color1">
                <span class="stripe stripe--small full-width"></span>
                <span class="rectangle rectangle--large">
                    <span class="book__title book__title--small">Taskrunner Grunt, Webpack</span>
                </span>
                <span class="stripe stripe--small full-width"></span>
            </div>

            <div class="book book--color2">
                <span class="stripe stripe--small full-width"></span>
                <span class="circle"></span>
                <span class="rectangle rectangle--large">
                    <span class="book__title">Vue.js</span>
                </span>
                <span class="stripe stripe--small full-width"></span>
            </div>

            <div class="book book--small book--color3">
                <span class="stripe stripe--small full-width"></span>
                <span class="rectangle rectangle--large">
                    <span class="book__title book__title--medium">JavaScript</span>
                </span>
                <span class="stripe stripe--double stripe--small full-width"></span>
            </div>

            <div class="book book--color4 book--small">
                <span class="stripe stripe--small full-width"></span>
                <span class="rectangle">
                    <span class="book__title book__title--small">Twig &amp; Smarty</span>
                </span>
                <span class="stripe stripe--double stripe--small full-width"></span>
            </div>

            <div class="book">
                <span class="stripe stripe--small full-width"></span>
                <span class="rectangle rectangle--white">
                    <span class="book__title book__title--large book__title--bottom">Adobe CS</span>
                </span>
                <span class="stripe stripe--large no-margin"></span>
            </div>

            <div class="book book--small book--color2">
                <span class="stripe stripe--small full-width"></span>
                <span class="rectangle rectangle--large">
                    <span class="book__title book__title--small">Web-Design</span>
                </span>
                <span class="stripe stripe--small full-width"></span>
            </div>
        </div>

        <div class="bookshelf"></div>
    </div>
</section>
</template>

<script>
export default {
    name: 'Kenntnisse',
    props: {
        msg: String,
    },
};
</script>

<style lang="scss" scoped>
@import '../../scss/custom/variables.scss';

$bookcolor1: $brand-green-light;
$bookcolor2: $brand-brown-dark;
$bookcolor3: $brand-green-dark;
$bookcolor4: $brand-grey-dark;
$shadow: rgba(0, 0, 0, 0.05);
$bookwidth: 110px;

.kenntnisse {
    background: $brand-brown;

    @media screen and (min-width: 1400px) {
        border-right: 20px solid $brand-brown-dark;
    }
}

@media screen and (max-width: 960px) {
    .order-2 {
        grid-column: 2;
    }
}

.bookshelf {
    height: 40px;
    background-color: $white;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.16);
    margin-left: -20px;
    width: calc(100% - 20px);
    position: absolute;
    bottom: 0;

    &__wrapper {
        grid-column: 2 / span 11;
        position: relative;
        padding: 0 20px 40px;
        margin-bottom: 40px;
    }

    &__books {
        position: relative;
        display: flex;
        flex-shrink: 0;
        align-items: flex-end;
        transform: scale(0.8);
        transform-origin: left bottom;

        @media screen and (min-width: 960px) {
            transform: scale(1);
            margin-top: 40px;
        }
    }
}

.deko {
    display: none;

    &.book--row-two {
        margin-right: 20px;
    }

    @media screen and (min-width: 960px) {
        display: block;
    }
}

.horizontal-block {
    width: 340px;
    transform: rotate(90deg);
    transform-origin: bottom left;
    display: flex;
    justify-content: flex-end;
}

.book {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 110px;
    height: 340px;
    background: $bookcolor1;
    align-items: center;

    @media screen and (min-width: 1080px) {
        &--horizontal {
            transform: rotate(90deg);
            transform-origin: bottom left;
            top: -110px;
        }

        &--leaning {
            transform: rotate(-15deg);
            transform-origin: bottom left;
            margin-left: 73px;
        }

        &--margin-negative {
            margin-left: -45px;
        }

        &--on-top {
            top: -$bookwidth * 2;
        }

        &--on-top-of-small {
            top: -$bookwidth;
        }
    }

    @media screen and (min-width: 960px) and (max-width: 1079px) {
        &--last-medium {
            transform: rotate(-15deg);
            transform-origin: bottom left;
            margin-left: 73px;
        }
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        width: $bookwidth/2;
        background-color: $shadow;
        height: 100%;
        display: block;
        left: $bookwidth/2;
    }

    &--color2 {
        background: $bookcolor2;

        .book__title {
            color: $white;
        }

        .rectangle--white {
            .book__title {
                color: $brand-grey-dark;
            }
        }
    }

    &--color3 {
        background: $bookcolor3;

        .book__title {
            color: $white;
        }

        .rectangle--white {
            .book__title {
                color: $brand-grey-dark;
            }
        }
    }

    &--color4 {
        background: $bookcolor4;

        .book__title {
            color: $white;
        }

        .rectangle--white {
            .book__title {
                color: $brand-grey-dark;
            }
        }
    }

    &--margin {
        margin-left: 30px;
    }

    &__title {
        writing-mode: vertical-rl;
        transform: rotate(180deg);
        font-size: 24px;
        line-height: 26px;
        color: $brand-grey-dark;
        align-self: center;

        &--large {
            font-size: 38px;
            line-height: 32px;
        }

        &--medium {
            font-size: 28px;
            line-height: 28px;
        }

        &--small {
            font-size: 18px;
            line-height: 20px;
            margin: auto;
        }

        &--bottom {
            align-self: flex-end;
            margin-bottom: 10px;
        }

        &--top {
            align-self: flex-start;
            margin-top: 10px;
        }
    }

    &--small {
        width: $bookwidth/2;
        height: 280px;

        &::after {
            width: $bookwidth/4;
            left: $bookwidth/4;
        }

        &.book .circle {
            width: 30px;
            height: 30px;
        }
    }

    .stripe {
        display: block;
        background-color: $white;
        width: 80%;
        height: 20px;
        margin: 20px auto;

        &--double {
            &::before {
                content: '';
                display: block;
                background-color: $white;
                width: 100%;
                height: 20px;
                margin: 20px auto;
            }
        }

        &--small {
            height: 10px;

            &.stripe--double {
                &::before {
                    height: 10px;
                }
            }
        }

        &--large {
            height: 40px;

            &.stripe--double {
                &::before {
                    height: 40px;
                }
            }
        }
    }

    .rectangle {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        width: 80%;
        height: 60%;

        &--white {
            background-color: $white;
            color: $brand-grey-dark;
            margin-top: 10px;
            margin-bottom: 10px;
            padding: 5px;
        }

        &--large {
            height: 83%;
        }
    }

    .circle {
        background-color: $white;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        margin: auto;
        flex-shrink: 0;
    }

    .no-margin {
        margin: 0 auto;
    }

    .full-width {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
}
</style>
