<template>
  <div class="newsbox">
    <input type="checkbox" id="closeNewsbox">
    <label for="closeNewsbox">&#10004;</label>
    <p class="newsbox__text">{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: "Newsbox",
  props: {
    headline: String,
    text: String
  }
};
</script>

<style lang="scss" scoped>
@import "../../scss/custom/variables.scss";

@supports (display: grid) {
  .newsbox {
    display: none;
  }
}

@supports not (display: grid) {
  .newsbox {
    position: fixed;
    bottom: 0;
    width: 88%;
    z-index: 10;
    margin: 0 4%;
    display: block;

    &__text {
      margin: 0;
      background: $brand-green-light;
      padding: 70px 2% 30px;
    }

    input[type="checkbox"] {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
    label {
      cursor: pointer;
      display: block;
      position: absolute;
      top: 20px;
      right: 20px;
      width: 40px;
      height: 40px;
      background: $white;
      text-align: center;
      padding-top: 3px;
      box-sizing: border-box;
    }

    input[type="checkbox"]:checked {
      & ~ .newsbox__text,
      & ~ label {
        opacity: 0;
        transition: opacity 1s;
      }
    }
  }
}
</style>
