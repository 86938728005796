<template>
	<div class="back-to-top__wrapper">
		<button class="btn back-to-top__button" title="Nach oben" @click.prevent="scrollToTop" :class="{'back-to-top__button--show': show}">
			<img src="../assets/svg/arrow-up.svg" alt="to top" class="back-to-top__icon">
		</button>
	</div>
</template>

<script>
export default {
	props: {
		offsetTrigger: {
			type: Number,
			default: window.innerHeight - 500
		}
	},

	data() {
		return {
			show: false
		};
	},

	methods: {
		scrollToTop() {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		}
	},

	mounted: function() {
		let offsetTrigger = parseInt(this.offsetTrigger);
		let watcherTop = this.$scrollMonitor.create(offsetTrigger);

		watcherTop.stateChange((e, state) => {
			this.show = state.isAboveViewport;
		});
	}
};
</script>

<style lang="scss" scoped>
@import '../../scss/custom/variables.scss';

.back-to-top {
	&__button {
		position: fixed;
		bottom: 20px;
		right: 30px;
		z-index: 10;
		border: 0;
		border-radius: 0;
		background-color: $brand-green-light;
		transition: transform 0.5s;
		transform: translate(0, 95px);
		pointer-events: none;
		line-height: 1;
		width: 50px;
		height: 50px;

		@media screen and (min-width: 1024px) {
			width: 70px;
			height: 70px;
		}

		&:hover {
			background-color: $brand-grey-dark;
			cursor: pointer;
		}
	}

	&__icon {
		width: 30px;

		@media screen and (min-width: 1024px) {
			width: 50px;
		}
	}
}

.back-to-top__button--show {
	transition: transform 0.5s;
	transform: translate(0, 0);
	pointer-events: all;
}
</style>
