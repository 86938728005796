<template>
  <section id="geleistetes" class="referenzen">
    <span class="font--vertical font--large">geleistetes</span>
    <h2 class="headline">{{ msg }}</h2>
    <div class="referenzen__container">
      <Referenz
        headline="Flying Tangram"
        desc="Tangram ohne JavaScript und ohne Bilder"
        infotext="Details"
        fileName="img/referenzen/tangram_overview.jpg"
        imgAlt="Screenshot vom Tangram"
        refLink="#referenzTangram"
        id="referenzTangramKlick"
      ></Referenz>
      <Referenz
        headline="Manuela Mahl"
        desc="Erstellung des CI - vom Logo bis zur Website"
        infotext="Details"
        fileName="img/referenzen/manuela_overview.jpg"
        imgAlt="Screenshot der Webseite Manuela Mahl"
        refLink="#referenzManuela"
        id="referenzManuelaKlick"
      ></Referenz>
      <Referenz
        headline="Mehr auf Codepen"
        desc="Mehr Experimente von mir gibt es auf codepen.io zu sehen"
        infotext="zu Codepen"
        fileName="img/referenzen/codepen_overview.jpg"
        imgAlt="Screenshot codepen"
        refLink="https://codepen.io/deeesi/pens/public/"
        target="_blank"
      ></Referenz>

      <ReferenzTangram/>
      <ReferenzManuela/>
    </div>
  </section>
</template>

<script>
import Referenz from "./Referenz.vue";
import ReferenzTangram from "./ReferenzTangram.vue";
import ReferenzManuela from "./ReferenzManuela.vue";

export default {
  name: "Referenzen",
  components: {
    Referenz,
    ReferenzTangram,
    ReferenzManuela
  },
  props: {
    msg: String
  }
};
</script>

<style lang="scss" scoped>
.referenzen {
  &__container {
    grid-column: 2 / span 10;
    display: grid;
    grid-template-columns: 1fr;
    gap: 80px 0;
    align-items: start;

    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
      gap: 120px 80px;
    }

    @media (min-width: 1200px) {
      gap: 120px;
    }
  }
}
</style>
