<template>
  <header id="top">
    <div class="nav__background"></div>

    <img src="../assets/svg/desi-gn_logo.svg" alt="desi-gn creative frontend Logo" class="logo">

    <Navigation/>

    <Social/>

    <Metanavi/>

    <div class="img__wrapper">
      <img src="img/header-img--tangram.jpg" alt="Bild Tangram">
      <img src="img/header-img--manuela.jpg" alt="Bild Manuela">
    </div>

    <Impressum/>
  </header>
</template>

<script>
import Navigation from "./Navigation.vue";
import Impressum from "./Impressum.vue";
import Social from "./Social.vue";
import Metanavi from "./Metanavi.vue";

export default {
  name: "Header",
  components: {
    Navigation,
    Impressum,
    Social,
    Metanavi
  },
  props: {
    msg: String
  }
};
</script>

<style lang="scss" scoped>
@import "../../scss/custom/variables.scss";

header {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 10px 110px 1fr 40px 40px 10px;
  gap: 12px;

  @media (min-width: 768px) {
    grid-template-rows: 20px 80px 150px 40px 26px 5px;
    gap: 20px;
  }

  @media (min-width: 810px) {
    grid-template-rows: 20px 100px 150px 40px 40px 20px;
  }

  @media (min-width: 1024px) {
    grid-template-rows: 20px 110px 170px 40px 40px 20px;
  }

  @media (min-width: 1280px) {
    grid-template-rows: 20px 130px 210px 40px 40px 20px;
  }
}

.nav__background {
  background-color: $brand-green;
  grid-column: 1 / span 10;
  grid-row: 1 / span 6;
  z-index: 0;

  @media (min-width: 768px) {
    grid-column: 1 / span 5;
  }

  @media (min-width: 1024px) {
    grid-column: 1 / span 5;
  }

  @media (min-width: 1280px) {
    grid-column: 1 / span 4;
  }
}

.img__wrapper {
  grid-column: 2 / span 11;
  grid-row: 3 / span 1;
  border: 10px solid $brand-grey-dark;
  border-right: 0;
  z-index: 1;
  align-self: start;
  position: relative;

  @media (min-width: 768px) {
    grid-column: 5 / span 8;
    grid-row: 2 / span 4;
  }

  @media (min-width: 1024px) {
    border: 20px solid $brand-grey-dark;
    border-right: 0;
    margin-left: 20px;
  }

  @media (min-width: 1280px) {
    grid-column: 4 / span 9;
    margin-left: 30px;
  }

  @media (min-width: 1400px) {
    border: 20px solid $brand-grey-dark;
  }

  img {
    width: 100%;
    display: block;

    &:nth-of-type(2) {
      animation-name: fade;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      animation-duration: 5s;
      animation-direction: alternate;
      position: absolute;
      top: 0;
    }
  }
}

@keyframes fade {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.logo {
  width: 100%;
  max-width: 150px;
  z-index: 1;
  grid-column: 2 / span 4;
  grid-row-start: 2;

  @media (min-width: 768px) {
    width: 100%;
    grid-column: 2 / span 2;
  }

  @media (min-width: 1024px) {
    margin-bottom: 25px;
    width: 150px;
  }

  @media (min-width: 1280px) {
    margin-bottom: 40px;
    width: 180px;
  }
}
</style>
