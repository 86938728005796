<template>
  <a class="btn btn--link" :class="`btn--${additionalClass}`" :href="href">
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: "SimpleLink",
  props: {
    additionalClass: String,
    href: {
      default: "#",
      href: String
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../scss/custom/variables.scss";

.btn {
  padding: 10px 40px;
  background: $white;
  position: relative;
  border: 0;
  font-size: 24px;
  font-family: $font-sans-serif;
  color: $text-color;
  transition: all 0.3s;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    display: block;
    left: 0;
    top: 50%;
    width: 100%;
    height: 50%;
    background-color: rgba(0, 0, 0, 0.07);
  }

  &:hover {
    background: $brand-green-dark;
    color: $white;

    &::after {
      content: "";
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}
</style>
