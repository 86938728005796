import Vue from 'vue';
import App from './App.vue';
import scrollMonitor from 'scrollmonitor';
import VueScrollTo from 'vue-scrollto';

Object.defineProperty(Vue.prototype, '$scrollMonitor', {
	value: scrollMonitor
});

Vue.config.productionTip = false;
Vue.use(VueScrollTo);

new Vue({
	render: h => h(App)
}).$mount('#app');
