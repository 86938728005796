<template>
  <div id="app">
    <Header/>
    <Lebenslauf msg="lebenslauf"/>
    <Kenntnisse msg="kenntnisse"/>
    <Referenzen msg="referenzen"/>
    <Kontakt msg="kontakt"/>
    <ToTop/>
    <Newsbox
      text="Ihr Browser unterstützt leider CSS Grid nicht. Für das optimale Nutzungserlebnis empfehle ich die aktuelle Version von Google Chrome oder Mozilla Firefox."
    />
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Lebenslauf from "./components/Lebenslauf.vue";
import Kenntnisse from "./components/Kenntnisse.vue";
import Referenzen from "./components/Referenzen.vue";
import Kontakt from "./components/Kontakt.vue";
import ToTop from "./components/ToTop.vue";
import Newsbox from "./components/Newsbox.vue";

export default {
  name: "app",
  components: {
    Header,
    Lebenslauf,
    Kenntnisse,
    Referenzen,
    Kontakt,
    ToTop,
    Newsbox
  }
};
</script>

<style lang="scss">
// import extra/basic stylesheets for the whole app
@import "scss/main.scss";

#app {
  max-width: 1400px;
  min-width: 320px;
}
</style>
